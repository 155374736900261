import { useCallback, useEffect, useMemo } from 'react';
import { stringify as toQueryString, parse as fromQueryString } from 'query-string';
import { useService } from '@atlassian/jira-common-services/src/use-service';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { ff } from '@atlassian/jira-feature-flagging';
import { fetchJson } from '@atlassian/jira-fetch';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';

const JQL_REGEX_OLD = /(?:text ~ )(.*?)(?= AND .*| OR .*| ORDER BY .*|$)/;
const JQL_REGEX = /(?:summary ~ )(.*?)( OR description ~ )(.*?)(?= AND .*| OR .*| ORDER BY .*|$)/;

type UseSmartQueryCheckReturn = {
	loading: boolean;
	error?: Error;
	data?: QuicksearchResponse;
	fetch: () => Promise<QuicksearchResponse | undefined>;
	isSmartQuery: boolean;
};

type QuicksearchResponse = {
	url: string;
};

export const useSmartQueryCheck = ({
	jql,
	wildcardAdded,
}: {
	jql?: string;
	wildcardAdded?: boolean;
}): UseSmartQueryCheckReturn => {
	const { atlassianAccountId } = useTenantContext();

	const request = useCallback(async () => {
		if (!atlassianAccountId || !jql) {
			return undefined;
		}
		let searchString;

		if (ff('nin_expose_text_option_jql_builder_4kwzo')) {
			searchString = (JQL_REGEX.exec(jql)?.[1] || '').replaceAll('"', '');
		} else {
			searchString = (JQL_REGEX_OLD.exec(jql)?.[1] || '').replaceAll('"', '');
		}

		if (wildcardAdded) {
			// remove the wildcard from the search string
			searchString = searchString.slice(0, -1);
		}
		const queryStr = toQueryString({ searchString, ignorePreference: true });

		return fetchJson<QuicksearchResponse>(`/rest/navigation/4/quicksearch?${queryStr}`);
	}, [atlassianAccountId, jql, wildcardAdded]);

	const { loading, error, data, fetch } = useService<QuicksearchResponse>(request);

	useEffect(() => {
		fetch();
	}, [fetch]);

	const isSmartQuery = useMemo(() => {
		const quicksearchUrl = data?.url;
		if (quicksearchUrl) {
			// @ts-expect-error - The "comma" option requires query-string v6.4.0 or newer. JFE currently uses v5.1.1.
			const query = fromQueryString(quicksearchUrl, { arrayFormat: 'comma' });
			if (query.quickSearchQuery !== undefined) {
				return true;
			}
		}
		return false;
	}, [data]);

	useEffect(() => {
		if (error) {
			fireErrorAnalytics({
				meta: {
					id: 'quicksearchSmartQueryDisabledFetch',
					packageName: 'jiraIssueNavigatorFlags',
					teamName: 'empanada',
				},
				error,
			});
		}
	}, [error]);

	return {
		loading,
		error,
		data,
		fetch,
		isSmartQuery,
	};
};

export default useSmartQueryCheck;
